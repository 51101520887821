<template>
    <div class="elevation-0 widget-box">
        <div class="d-flex flex-row justify-space-between align-center">
            <v-tabs class="dashboard-table-tabs" v-model="tab">
                <v-tab>
                    Сегменты клиентов
                </v-tab>
                <v-tab>
                    Сегменты товаров
                </v-tab>
                <v-tab>
                    Точки магазинов
                </v-tab>
            </v-tabs>
            <div class="d-flex flex-row">
                <div class="d-flex flex-row align-center">
                    <v-menu
                        v-model="exportMenu"
                        offset-y
                        :close-on-content-click="true"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="export-button"
                                text
                                secondary
                                color="#6693FF"
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon size="16px">
                                    $iconify_ion-document-outline
                                </v-icon>
                                Экспорт
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item  @click="exportXls('xlsx')">
                                <v-list-item-title>Excel</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportXls('csv')">
                                <v-list-item-title>CSV</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                    
                <div class="mr-1">
                    <v-menu 
                        v-model="filterMenu"
                        offset-y
                        :close-on-content-click="false"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="secondary"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon 
                                    :small="$vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? false : true"
                                    color="#6693FF"
                                >
                                    $iconify_ion-options-outline 
                                </v-icon>
                            </v-btn>
                        </template>
                        <filter-menu 
                            title="Фильтр" 
                            description="Выберите сегмент клиентов, по которым будет отображаться статистика"
                            name="table"
                            :allUsers="false"
                            :maxLength="4"
                            :type="filterType"
                            :segment="segments"
                            @close="filterMenu = false"
                            v-on="$listeners"
                        >

                        </filter-menu>
                    </v-menu>
                </div>
                <v-btn
                    color="#6693FF"
                    @click="sidePanel = true"
                    class="settings-icon"
                  >
                    <v-icon 
                      :small="$vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? false : true"
                      color="#FFF"
                      size="16px"
                    >
                      $iconify_feather-settings
                    </v-icon>
                </v-btn>
            </div>
        </div>
        <div>
            <v-row>
                <v-col v-if="!loading">
                    <v-data-table
                        class="plus-table segments-table"
                        :options="tableSettings"
                        hide-default-footer
                        :headers="tableHeaders"
                        :items="tableData"
                        :loading="loading"
                        ref="dataTable"
                    >
                    <template v-slot:item.segments=" {item} ">
                        <segment-chip 
                            :name="item.segmentName" 
                            max-width="100px"
                            chip
                            :style="'color: #000000; background-color: ' + hexToRgbA(item.bgColor, 0.15) "
                        >

                        </segment-chip>
                    </template>
                    <template v-slot:item.clients=" {item} ">
                        {{ item.clientCount }}
                    </template>
                    <template v-slot:item.active_clients=" {item} ">
                        {{ item.clientActive }}
                    </template>
                    <template v-slot:item.income=" {item} ">
                        {{ item.salesSum }} {{ currency.symbol }}
                    </template>
                    <template v-slot:item.sales=" {item} ">
                        {{ item.salesCount }}
                    </template>
                    <template v-slot:item.avg_check=" {item} ">
                        {{ item.avgCheck.toFixed(2) }} {{ currency.symbol }}
                    </template>
                    <template v-slot:item.sales_freq=" {item} ">
                        {{ item.salesFreq }}
                    </template>
                    <template v-slot:item.new_clients=" {item} ">
                        {{ item.clientNew }}
                    </template>
                    <template v-slot:item.bonus_credit=" {item} ">
                        {{ item.bonusCredit }}
                    </template>
                    <template v-slot:item.bonus_debit=" {item} ">
                        {{ item.bonusDebit }}
                    </template>
                    <template v-slot:item.bonus_expire=" {item} ">
                        {{ item.bonusExpire }}
                    </template>
                    <template v-slot:item.bonus_credit_sum=" {item} ">
                        {{ item.bonusCreditSum.toFixed(0) }} %
                    </template>
                    <template v-slot:item.bonus_debit_sum=" {item} ">
                        {{ item.bonusDebitSum.toFixed(0) }} %
                    </template>
                    <template v-slot:item.bonus_expire_sum=" {item} ">
                        {{ item.bonusExpireSum.toFixed(0) }} %
                    </template>
                    <template v-slot:item.bonus_percent=" {item} ">
                        {{ item.bonusPercent.toFixed(0) }} %
                    </template>

                    <!--Сегменты товаров-->

                    <template v-slot:item.items=" {item} ">
                        {{ item.itemsAll}} 
                    </template>
                    <template v-slot:item.items_count=" {item} ">
                        {{ item.itemsCount}} 
                    </template>
                    <template v-slot:item.avg_count_in_check=" {item} ">
                        {{ item.avgCountInCheck.toFixed(2) }} 
                    </template>
                    <template v-slot:item.avg_discount=" {item} ">
                        {{ item.avgDiscount.toFixed(2) }} {{ currency.symbol }}
                    </template>
                    <template v-slot:item.items_freq=" {item} ">
                        {{ item.itemsFreq.toFixed(2) }} 
                    </template>
                    <template v-slot:item.items_client_count=" {item} ">
                        {{ item.itemsClientCount }} 
                    </template>
                    <template v-slot:item.avg_income=" {item} ">
                        {{ item.avgIncome.toFixed(2) }} {{ currency.symbol }}
                    </template>
                    <template v-slot:item.sales_income=" {item} ">
                        {{ item.income }} {{ currency.symbol }}
                    </template>

                    <!--Торговые точки-->
                    <template v-slot:item.shop=" {item} ">
                        {{ item.shopName}} 
                    </template>
                    <template v-slot:item.shop_sales_count=" {item} ">
                        {{ item.shopSalesCount}} 
                    </template>
                    <template v-slot:item.shop_income=" {item} ">
                        {{ item.shopIncome}} {{ currency.symbol }}
                    </template>
                    <template v-slot:item.shop_avg_income=" {item} ">
                        {{ item.shopAvgIncome.toFixed(2)}} {{ currency.symbol }}
                    </template>
                    <template v-slot:item.shop_avg_check=" {item} ">
                        {{ item.shopAvgCheck.toFixed(2)}} {{ currency.symbol }}
                    </template>
                    <template v-slot:item.shop_bonus_credit=" {item} ">
                        {{ item.shopBonusCredit}}
                    </template>
                    <template v-slot:item.shop_bonus_debit=" {item} ">
                        {{ item.shopBonusDebit}}
                    </template>
                    <template v-slot:item.shop_bonus_expire=" {item} ">
                        {{ item.shopBonusExpire}}
                    </template>
                    <template v-slot:item.shop_bonus_credit_sum=" {item} ">
                        {{ item.shopBonusCreditSum.toFixed(0) }} %
                    </template>
                    <template v-slot:item.shop_bonus_debit_sum=" {item} ">
                        {{ item.shopBonusDebitSum.toFixed(0) }} %
                    </template>
                    <template v-slot:item.shop_bonus_expire_sum=" {item} ">
                        {{ item.shopBonusExpireSum.toFixed(0) }} %
                    </template>
                    <template v-slot:item.shop_bonus_percent=" {item} ">
                        {{ item.shopBonusPercent.toFixed(0) }} %
                    </template>
                    <template v-slot:item.shop_active_promo=" {item} ">
                        {{ item.shopActivePromo }} 
                    </template>

                    </v-data-table>
                </v-col>
                <v-col v-else class="loader-wrapper">
                    <div class="loader">
                        <div class="spinner">
                            <div class="spinner__circle">
                                <div class="spinner__circle-gradient"></div>

                                <div class="spinner__circle-inner"></div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <!--<v-row
                align="center"
                class="pagination"
            >
                <v-col>
                    <div class="table-pagination-block">
                        <div
                            style="margin-right: 20px;"
                        >
                        Всего n страниц
                        </div>

                        <select-page-limit
                            min-width="200px"
                            :items="paginationOptions"
                            :model.sync="tableSettings.itemsPerPage"
                            item-value="value"
                            item-label="text"
                        />

                        <div class="app__spacer" />

                        <div class="text-center">
                            <v-pagination
                                v-model="tableSettings.page"
                                next-icon="fas fa-chevron-right"
                                prev-icon="fas fa-chevron-left"
                                :length="2"
                                :total-visible="7"
                                circle
                            />
                        </div>
                    </div>
                </v-col>
            </v-row>-->
        </div>
        <TableSidePanel 
            :selected="selectedHeaders" 
            :active="sidePanel"
            :tab="tab"
            @activeChange="sidePanel = $event"
            @changeHeaders="setHeaders($event)">
        
        </TableSidePanel>
    </div>
    
</template>

<script>
import SelectPageLimit from '@/components/dialogs/SelectPageLimit'
import DataTableMixin from '@/mixins/dataTable'
import SegmentChip from '@/components/segment/SegmentChip'
import FilterMenu from '@/views/widgets/frames/FilterMenu.vue'
import TableSidePanel from '../components/tableSidePanel.vue'
import Convertor from '@/mixins/convertor'
import XLSX from 'xlsx'
export default {
    // :items="clients"
    //         :options.sync="tableOptions"
    //         :word-operations="['клиент', 'клиента', 'клиентов']"
    //         :show-expand="false"
    //         :server-items-length="totalCount"
    //         :storage-key="tableKey"
    //         @update:sort-by="fetchData('sort-by')"
    //         @update:sort-desc="fetchData('sort-desc')"
    //         @click:row="editSidePanel"
    mixins : [DataTableMixin, Convertor],
    components : {SelectPageLimit, SegmentChip, FilterMenu, TableSidePanel},
    props:{
        tableData : {type: Array, default: null},
        currency : {type: Object, default: null},
        segments: Array
    },
    data(){
        return{
            tab : 0,
            sidePanel : false,
            filterMenu : false,
            exportMenu : false,
            activeTableData : [],
            selectedHeaders : [],
            activeHeaders:[],
            clientSegmentsHeaders:[
                { text: 'Сегмент', value: "segments", class: 'pre-header', sortable: false},
                { text: 'Клиенты', value: "clients", sortable: false,  },
                { text: 'Новые клиенты', value: "new_clients", sortable: false, },
                { text: 'Активные клиенты', value: "active_clients", sortable: false,  },
               // { text: 'Новые активные', value: "new_active", sortable: false,  },
                { text: 'Начислено бонусов', value: "bonus_credit", sortable: false,  },
                { text: 'Списано бонусов', value: "bonus_debit", sortable: false,  },
                { text: 'Сгорело бонусов', value: "bonus_expire", sortable: false,  },
                { text: 'Начислено бонусов от суммы покупок', value: "bonus_credit_sum", sortable: false,  },
                { text: 'Списано бонусов от суммы покупок', value: "bonus_debit_sum", sortable: false,  },
                { text: 'Сгорело бонусов от суммы покупок', value: "bonus_expire_sum", sortable: false,  },
                { text: 'Процент списания бонусов от количества начисленных', value: "bonus_percent", sortable: false,  },
                { text: 'Доход', value: "income", sortable: false,  },
                { text: 'Средний доход', value: "avg_income", sortable: false,  },
                { text: 'Средний чек', value: "avg_check",  },
                { text: 'Количество покупок', value: "sales", sortable: false, class: 'pre-header' , },
                { text: 'Частота покупок', value: "sales_freq", sortable: false, },
            ],
            productSegmentsHeaders:[
                { text: 'Сегмент', value: "segments", class: 'pre-header', sortable: false },
                { text: 'Всего товаров', value: "items", class: 'pre-header', sortable: false },
                { text: 'Количество покупок', value: "items_count", sortable: false },
                { text: 'Доход', value: "sales_income", sortable: false,  width: '100px'},
                { text: 'Средний доход', value: "avg_income", sortable: false },
                { text: 'Среднее количество товара в одном чеке', value: "avg_count_in_check", sortable: false },
                { text: 'Средняя скидка', value: "avg_discount", sortable: false, class: 'pre-header' },
                { text: 'Частота покупок товара', value: "items_freq", sortable: false, class: 'pre-header' },
                { text: 'Количество клиентов купивших товар', value: "items_client_count", sortable: false },
            ],
            salePointsHeaders: [
                { text: 'Магазин', value: "shop", class: 'pre-header' },
                { text: 'Покупок на точке', value: "shop_sales_count", sortable: false },
                { text: 'Доход точки', value: "shop_income", sortable: false },
                { text: 'Средний доход', value: "shop_avg_income", sortable: false },
                { text: 'Средний чек', value: "shop_avg_check", sortable: false, class: 'pre-header' },
                { text: 'Начислено бонусов', value: "shop_bonus_credit", sortable: false,  },
                { text: 'Списано бонусов', value: "shop_bonus_debit", sortable: false,  },
                { text: 'Сгорело бонусов', value: "shop_bonus_expire", sortable: false,  },
                { text: 'Начислено бонусов от суммы покупок', value: "shop_bonus_credit_sum", sortable: false,  },
                { text: 'Списано бонусов от суммы покупок', value: "shop_bonus_debit_sum", sortable: false,  },
                { text: 'Сгорело бонусов от суммы покупок', value: "shop_bonus_expire_sum", sortable: false,  },
                { text: 'Процент списания бонусов от количества начисленных', value: "shop_bonus_percent", sortable: false,  },
                { text: 'Действующие акции', value: "shop_active_promo", sortable: false,  },
            ],
            emptyHeaders :[
                {text: 'Сегмент', value: 'segments'},
                {text: '---', value: 'empty1'},
                {text: '---', value: 'empty2'},
                {text: '---', value: 'empty3'},
                {text: '---', value: 'empty4'},
                {text: '---', value: 'empty5'},
                {text: '---', value: 'empty6'},
                {text: '---', value: 'empty7'},
            ]
        }
    },
    computed:{
        tableHeaders(){
            return this.activeHeaders
        },
        loading(){
            return this.$store.getters['dashboard/table/loading']
        },
        filterType(){
            if (this.tab === 0) return "segment"
            if (this.tab === 1) return "nomenclature"
            if (this.tab === 2) return "shop"
        }
    },
    watch:{
        sidePanel(v){
            console.log("SIDE PANEL", v)
            if (!v){
                this.selectedHeaders=[]
                for (let i = 1; i < this.activeHeaders.length ;i++)
                    this.selectedHeaders.push({text : this.activeHeaders[i].text, value : this.activeHeaders[i].value})
            }
        },
        tab (v){
            this.$emit('changeTab', v)
            this.setDefaultHeaders()
        }
    },
    created(){
        for (let i = 0; i < 9 ;i++){
            this.activeHeaders.push(this.clientSegmentsHeaders[i])
            if (i > 0)
                this.selectedHeaders.push({text : this.clientSegmentsHeaders[i].text, value : this.clientSegmentsHeaders[i].value})
        }
    },
    methods:{
        setHeaders(e){
            console.log("SET HEADERS", e);
            this.activeHeaders = []
            this.selectedHeaders = []
            if (this.tab === 0)
                this.activeHeaders.push(this.clientSegmentsHeaders[0])
            if (this.tab === 1)
                this.activeHeaders.push(this.productSegmentsHeaders[0])
            if (this.tab === 2)
                this.activeHeaders.push(this.salePointsHeaders[0])
            for (let i = 0; i < e.length ;i++){
                let foundElem = {}
                if (this.tab === 0)
                    foundElem = this.clientSegmentsHeaders.find((el) => {return el.value === e[i]})
                if (this.tab === 1)
                    foundElem = this.productSegmentsHeaders.find((el) => {return el.value === e[i]})
                if (this.tab === 2)
                    foundElem = this.salePointsHeaders.find((el) => {return el.value === e[i]})
                this.activeHeaders.push(foundElem)
                this.selectedHeaders.push(foundElem)
            }
        },
        setDefaultHeaders(){
            this.activeHeaders = []
            this.selectedHeaders = []
            if (this.tab === 0)
                this.activeHeaders.push(this.clientSegmentsHeaders[0])
            if (this.tab === 1)
                this.activeHeaders.push(this.productSegmentsHeaders[0])
            if (this.tab === 2)
                this.activeHeaders.push(this.salePointsHeaders[0])
            for (let i = 1; i < 9 ;i++){
                if (this.tab === 0)
                    this.activeHeaders.push(this.clientSegmentsHeaders[i])
                if (this.tab === 1)
                    this.activeHeaders.push(this.productSegmentsHeaders[i])
                if (this.tab === 2)
                    this.activeHeaders.push(this.salePointsHeaders[i])

                this.selectedHeaders.push({text : this.activeHeaders[i].text, value : this.activeHeaders[i].value})
            }
        },
        exportXls(filetype){
            let data = []
            let filename = ""
            if (this.tab === 0)
                filename = "Сегменты клиентов"
            if (this.tab === 1)
                filename = "Сегменты товаров"
            if (this.tab === 2)
                filename = "Торговые точки"

            const now = new Date().toISOString()
            const book = XLSX.utils.table_to_book(document.getElementsByTagName('table')[0])

            XLSX.writeFile(book,filename + '_' + now + '.' + filetype)
        }
    }
}
</script>

<style lang="scss" scoped>
.segments-table-wrapper{
    border-radius: 10px;
    border: 1px solid #f2f2f7;
    padding: 12px;
}

.secondary.v-btn , .v-btn.theme--light.v-size--default{
  padding: 7px 10px !important;
  height: 29px;
  width: 35px;
  min-width: unset;
}

.export-button.v-btn.theme--light.v-size--default{
    margin-right: 10px;
    padding: 0px 0px !important;
    height: 16px;
    width: unset;

    .v-icon{
        margin-right: 5px;
    }
}

.table-pagination-block{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.v-data-table .v-data-table__wrapper table tbody tr td:first-child{
    width: 114px;
    text-overflow: clip;
}
.text-start{
    width: 114px;;
}
.v-menu__content.theme--light.menuable__content__active{
  border-radius: 12px 12px 12px 12px !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border : none;
  max-width: 527px;
}

.v-tab{
    font-size: 15px;
    font-weight: 600;
    line-height: 21;

    height: 21px;
}

.loader-wrapper{
    margin-top: 10px;
    $primaryColor: #4776E6;
    $secondaryColor: #F5F8FF;

    
    .loader{
        background-color: #F5F8FF;
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        @keyframes spin {
            100% {
                transform: rotate(360deg);
            }
        }
        .spinner {
            &__circle {
                position: relative;
                width: 54px;
                height: 54px;
                overflow: hidden;
                background-color: $primaryColor;
                border-radius: 100%;
                animation: spin 1s infinite linear;

                &-inner,
                &-gradient {
                    position: absolute;
                    width: 44px;
                    height: 44px;
                    transform: translate(-50%, -50%);
                }

                &-inner {
                    top: 50%;
                    left: 50%;
                    background-color: $secondaryColor;
                    border-radius: 100%;
                }

                &-gradient {
                    top: -24px;
                    left: 6px;
                    background-color: $primaryColor;
                    background-image:    -moz-linear-gradient(0deg,$primaryColor 0%,$secondaryColor 100%);
                    background-image: -webkit-linear-gradient(0deg,$primaryColor 0%,$secondaryColor 100%);
                    background-image:         linear-gradient(0deg,$primaryColor 0%,$secondaryColor 100%);
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
</style>

<style lang="scss">
.dashboard-table-tabs{
    .v-tabs{
        height: 29px;
    }
    .v-tab{
        letter-spacing: unset;
        text-transform: unset;
    }
    .v-tabs.theme--light{
        height: 29px;
    }
    .v-tabs-bar{
        height : unset;
    }
    .v-slide-group__wrapper{
        height: 29px;
    }
    .v-tabs-slider-wrapper{
        width: 193px;
        .v-tabs-slider{
            width: 81%;
            position: absolute;
            left: 17px;
        }
    }
}

.segments-table{
    th.text-start{
        width: 114px;
    }
    td.text-start{
        width: 114px;
    }
}
</style>
