<template>
  <widget-template :class-name="widgetClasses">
    <template v-slot:header-left>
      <p class="body-m-semibold">
        {{ title }}
      </p>
      <iconify-icon
        class="icon-question ml-1"
        icon="question-circle-outlined"
        height="16"
        style="cursor: pointer;"
        @click="dialogHelp = true"
      />
      <dialog-help
        :dialog.sync="dialogHelp"
        :header="title"
        :text="textHelp"
      />
    </template>

    <template v-slot:header-right>
      <div>
        <v-menu 
          v-model="filterMenu"
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="secondary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon 
                :small="$vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? false : true"
                color="#6693FF"
              >
                $iconify_ion-options-outline 
              </v-icon>
            </v-btn>
          </template>
          <filter-menu 
            title="Фильтр" 
            description="Выберите сегмент клиентов, по которым будет отображаться статистика"
            name="round"
            @close="filterMenu = false"
            v-on="$listeners"
            :allUsers="false"
            :segment="displaySegments"
            :maxLength="3"
          >

          </filter-menu>

        </v-menu>
      </div>
    </template>

    <template v-slot:body>
      <div class="d-flex flex-row justify-space-between round-body-wrapper">
        <div>
          <segment-chip 
            v-for="item, idx in displaySegments"
            max-width="136px"
            :key="idx"
            :name="item.name"
            :color="item.color"
          />
        </div>
        
        <circular-progress
          :class="generateClassesByPrefix(widgetClasses, '__circular-progress')"
          :stroke-width="12"
          :stroke-background="theme['primary-100']"
          :stroke-color="theme['primary']"
          :radius="52"
          :transition-duration="400"
          :value="segments"
          :colors="[
            hexToRgbA(displaySegments[0].color, '0.5'), 
            hexToRgbA(displaySegments[1].color, '0.5'), 
            hexToRgbA(displaySegments[2].color, '0.5'), 
          ]"
        >
          <div :class="generateClassesByPrefix(widgetClasses, '__circular-progress__data')">
            <p v-if="segments[0]" class="body-l-semibold" :style="'color:' + displaySegments[0].color" >
              + {{ segments[0].percent_label }}%
            </p>
            <p v-if="segments[1]" class="body-l-semibold" :style="'color:' + displaySegments[1].color">
              + {{ segments[1].percent_label }}%
            </p>
            <p v-if="segments[2]" class="body-l-semibold" :style="'color:' + displaySegments[2].color">
              + {{ segments[2].percent_label }}%
            </p>
          </div>
        </circular-progress>
      </div>

    </template>

    <template v-slot:footer>
      
    </template>
  </widget-template>
</template>

<script>
  import CircularProgress from '@/views/widgets/components/CircularProgress'
  import WidgetFunctions from '@/views/widgets/mixins/WidgetFunctions.js'
  import WidgetTemplate from '@/views/widgets/components/WidgetTemplate'
  import DialogHelp from '@/views/widgets/frames/DialogHelp'
  import FilterMenu from '@/views/widgets/frames/FilterMenu.vue'
  import SegmentChip from '@/components/segment/SegmentChip'
  import Convertor from '@/mixins/convertor'
  export default {
    name: 'RoundFrame',
    components: {
      WidgetTemplate,
      CircularProgress,
      DialogHelp,
      FilterMenu,
      SegmentChip
    },
    mixins: [WidgetFunctions, Convertor],
    inheritAttrs: false,
    props: {
      title: {
        type: String,
        default: '',
      },
      allCount: {
        type: Number,
        default: 0,
      },
      currentCount: {
        type: Number,
        default: 0,
      },
      percentageDifference: {
        type: Number,
        default: 0,
      },
      segments:{
        type: Array,
        default: null
      },
      textHelp: {
        type: String,
        default: null,
      },
      name: {
        type: String,
      },
      displaySegments: Array
    },
    data () {
      return {
        dialogHelp: false,
        filterMenu: false,
        labelColors: ['#4776E6', '#00FF00' , '#aa00FF'],
        selectedSegments : [
          {name : "Выберите сегмент", color : "#B5B5C4"},
          {name : "Выберите сегмент", color : "#B5B5C4"},
          {name : "Выберите сегмент", color : "#B5B5C4"},
        ],
      }
    },
    computed: {
      widgetClasses () {
        return this.parentClass !== undefined ? this.parentClass + ' f-round' : 'f-round'
      },
      circlePercentageDifference () {
        return this.relativeChange(this.allCount, this.currentCount)
      },
    },
    mounted () {},
    methods: {
      changeSegment(segment){
        console.log(segment)
        this.selectedSegments = [];
        if (segment.segment_id.length > 0){
          segment.segment_id.forEach((i) => {
            let found = this.$store.getters['crm/segment/segments'].find((j) =>{
              console.log('segments', i, j)
              return i === j.id
            })
            console.log('found', found);
            this.selectedSegments.push({name : found.name, color: found.color})
          })
        }
        while(this.selectedSegments.length < 3){
          this.selectedSegments.push({name : "Выберите сегмент", color : "#B5B5C4"})
        }
      },
      clearSegment(){
        this.selectedSegments = [
          {name : "Выберите сегмент", color : "#B5B5C4"},
          {name : "Выберите сегмент", color : "#B5B5C4"},
          {name : "Выберите сегмент", color : "#B5B5C4"}
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>

@import "@/styles/vuetify-preset-plus/light_theme/widgets/frames/_round-frame.scss";
.v-menu__content.theme--light.menuable__content__active{
  border-radius: 12px 12px 12px 12px !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border : none;
  max-width: 527px;
}

.round-body-wrapper{
  margin-top: 53px;
  width: 100%;
}

.circle{
  min-width: 10px;
  min-height: 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-left: 5px;
}

.legend-wrapper{
  width: 100%;
}

.legend-item{
  max-width: 33%;
}

.legend-item span{
  overflow: hidden;
  text-overflow: clip;
  height: 23px;
  max-width: 90%;
}

.segment-chip{
    margin-bottom: 10px;
    padding: 7px 10px;
    border-radius : 5px;
}

.secondary.v-btn{
  padding: 7px 10px !important;
  height: 29px;
  width: 35px;
  min-width: unset;
}
</style>
