<template>
  <v-row class="widget-line">
    <v-col
      :cols="6"
      :sm="6"
      :md="4"
    >
      <client-program 
        :widget-data="programClients"
        :segment="getSegmentById(widgetOptions.clients.segment_id)"
        @selectSegment="selectSegment($event)"
        @filterClear="clearSegment('client')"
      />
    </v-col>
    <v-col
      :cols="6"
      :sm="6"
      :md="4"
    >
      <purchase 
        :widget-data="purchases" 
        :currency="program.currency" 
        :segment="getSegmentById(widgetOptions.purchase.segment_id)"
        @selectSegment="selectSegment($event)" 
        @filterClear="clearSegment('purchase')"/>
    </v-col>
    <v-col
      :cols="6"
      :sm="6"
      :md="4"
    >
      <share-purchase 
        :widget-data="sharePurchases" 
        :segment="[
          getSegmentById(this.widgetOptions.round.segments_ids[0] > 0 ? this.widgetOptions.round.segments_ids[0] : null),
          getSegmentById(this.widgetOptions.round.segments_ids[1] > 0 ? this.widgetOptions.round.segments_ids[1] : null),
          getSegmentById(this.widgetOptions.round.segments_ids[2] > 0 ? this.widgetOptions.round.segments_ids[2] : null),
        ]"
        @selectSegment="selectSegment($event)"
        @filterClear="clearSegment('round')"/>
    </v-col>
    <v-col
      :cols="8"
      :sm="9"
      :md="12"
    >
      <movement-bonuses 
        :widget-data="bonuses" 
        :currency="program.currency" 
        :segment="getSegmentById(widgetOptions.movement.segment_id)"
        @selectSegment="selectSegment($event)"
        @filterClear="clearSegment('bonuses')"
      />
    </v-col>
    <v-col>
      <SegmentsTable 
        :tableData="table" 
        :currency="program.currency"
        :segments="getTableSegmentById()"
        @selectSegment="selectSegment($event)"
        @changeTab="changeTableTab($event)"
        @filterClear="clearSegment('table')"
      >

      </SegmentsTable>
    </v-col>

   
    
  </v-row>
</template>

<script>
  import Purchase from '@/views/widgets/Purchase'
  import SharePurchase from '@/views/widgets/SharePurchase'
  import ClientProgram from '@/views/widgets/ClientProgram'
  import MovementBonuses from '@/views/widgets/MovementBonuses'
  import BonusRatio from '@/views/widgets/BonusRatio'
  import CustomerSegment from '@/views/widgets/CustomerSegment'
  import SegmentsTable from '../../widgets/frames/SegmentsTable.vue'
  export default {
    components: {
      ClientProgram,
      Purchase,
      SharePurchase,
      MovementBonuses,
      BonusRatio,
      CustomerSegment,
      SegmentsTable
    },
    props: {
    },
    data () {
      return {
        start_period: null,
        end_period: null,
        widgetOptions: {
          clients :{
            all_clients : false,
            segment_id : null,
          },
          purchase:{
            all_clients : false,
            segment_id : null,
          },
          movement :{
            all_clients: false,
            segment_id : null,
          },
          round :{
            segments_selected : false,
            segments_ids : [],
          },
          table:{
            tab : 0,
            segments_ids: []
          }
        }
        
      }
    },
    computed: {
      program () {
        return this.$store.getters['company/program/program']
      },
      period () {
        return this.$store.getters['reference/date_selection/period']
      },
      programClients () {
        return this.$store.getters['dashboard/clients/widgetData']
      },
      purchases () {
        return this.$store.getters['dashboard/purchase/widgetData']
      },
      bonuses () {
        console.log("BONUSES", this.$store.getters['dashboard/bonuses/widgetData'])
        return this.$store.getters['dashboard/bonuses/widgetData']
      },
      sharePurchases(){
        return this.$store.getters['dashboard/sharePurchase/widgetData']
      },
      segments () {
          return this.$store.getters['crm/segment/segments'] 
      },
      nomenclature(){
        return this.$store.getters['company/nomen_segment/list']
      },
      shops(){
        return this.$store.getters['company/program/shops']
      },
      table(){
        if (this.widgetOptions.table.segments_ids.length === 0)
          return this.$store.getters['dashboard/table/emptyData']
        if (this.widgetOptions.table.tab === 0)
          return this.$store.getters['dashboard/table/widgetDataClients']
        if (this.widgetOptions.table.tab === 1){
          if (this.nomenclature){
            let data = this.$store.getters['dashboard/table/widgetDataNomenclature']
            console.log("index nomenclature", this.nomenclature)
            
            data.forEach(element => {
              let foundElem = this.nomenclature.find((elem, idx) => {return elem.id === element.id})
              if (foundElem)
                element.itemsAll = foundElem.nomenclatures_count
            });
            return data;
          }
          return this.$store.getters['dashboard/table/widgetDataNomenclature']
        }
        if (this.widgetOptions.table.tab === 2){
          return this.$store.getters['dashboard/table/widgetDataShops']
        }
      }
    },
    watch: {
      period (v) {
        this.start_period = v.start
        this.end_period = v.end

        this.$store.dispatch('dashboard/clients/widget', {
          program_id: this.program.id,
          start_period: v.start,
          end_period: v.end,
          all_clients :this.widgetOptions.clients.all_clients,
          segment_id : this.widgetOptions.clients.segment_id === 0 ? null : this.widgetOptions.clients.segment_id
        })

        this.$store.dispatch('dashboard/purchase/widget', {
          program_id: this.program.id,
          start_period: v.start,
          end_period: v.end,
          all_clients : this.widgetOptions.purchase.all_clients,
          segment_id : this.widgetOptions.purchase.segment_id === 0 ? null : this.widgetOptions.purchase.segment_id
        })

        this.$store.dispatch('dashboard/bonuses/widget', {
          program_id: this.program.id,
          start_period: v.start,
          end_period: v.end,
          all_clients : this.widgetOptions.movement.all_clients,
          segment_id : this.widgetOptions.movement.segment_id === 0 ? null : this.widgetOptions.movement.segment_id
        })


        this.$store.dispatch('dashboard/sharePurchase/widget', {
          program_id: this.program.id,
          start_period: v.start,
          end_period: v.end,
          segments_selected :this.widgetOptions.round.segments_selected,
          segments_ids : this.widgetOptions.round.segments_ids.length === 0 ? [] : this.widgetOptions.round.segments_ids
        })


        this.$store.dispatch('dashboard/table/clearPeriod')
        if (this.widgetOptions.table.tab === 0)
          this.$store.dispatch('dashboard/table/tabClient', {
            program_id: this.program.id,
            start_period: v.start,
            end_period: v.end,
            segments_ids : this.widgetOptions.table.segments_ids
          })

        if (this.widgetOptions.table.tab === 1)
          this.$store.dispatch('dashboard/table/tabNomenclatures', {
            program_id: this.program.id,
            start_period: v.start,
            end_period: v.end,
            segments_ids : this.widgetOptions.table.segments_ids
          })

        if (this.widgetOptions.table.tab === 2)
          this.$store.dispatch('dashboard/table/tabShops', {
            program_id: this.program.id,
            start_period: v.start,
            end_period: v.end,
            shops_ids : this.widgetOptions.table.segments_ids
          })
      },
      async program (v) {
        await this.resetWidgetOptions(v)

        this.$store.dispatch('dashboard/clients/widget', {
          program_id: v.id,
          start_period: this.period.start,
          end_period: this.period.end,
          all_clients :this.widgetOptions.clients.all_clients,
          segment_id : this.widgetOptions.clients.segment_id === 0 ? null : this.widgetOptions.clients.segment_id
        })

        this.$store.dispatch('dashboard/purchase/widget', {
          program_id: v.id,
          start_period: this.period.start,
          end_period: this.period.end,
          all_clients :this.widgetOptions.purchase.all_clients,
          segment_id : this.widgetOptions.purchase.segment_id === 0 ? null : this.widgetOptions.purchase.segment_id
        })

        this.$store.dispatch('dashboard/bonuses/widget', {
          program_id: v.id,
          start_period: this.period.start,
          end_period: this.period.end,
          all_clients : this.widgetOptions.movement.all_clients,
          segment_id : this.widgetOptions.movement.segment_id === 0 ? null : this.widgetOptions.movement.segment_id
        })


        this.$store.dispatch('dashboard/sharePurchase/widget', {
          program_id: v.id,
          start_period: this.period.start,
          end_period: this.period.end,
          segments_selected :this.widgetOptions.round.segments_selected,
          segments_ids : this.widgetOptions.round.segments_ids.length ? [] : this.widgetOptions.round.segments_ids
        })

        if (this.widgetOptions.table.tab === 0)
          await this.$store.dispatch('dashboard/table/tabClient', {
            program_id: v.id,
            start_period: this.period.start,
            end_period: this.period.end,
            segments_ids : this.widgetOptions.table.segments_ids
          })

        if (this.widgetOptions.table.tab === 1){
          await this.$store.dispatch('dashboard/table/tabNomenclatures', {
            program_id: v.id,
            start_period: this.period.start,
            end_period: this.period.end,
            segments_ids : this.widgetOptions.table.segments_ids
          })
        }

        if (this.widgetOptions.table.tab === 2){
          await this.$store.dispatch('dashboard/table/tabShops', {
            program_id: v.id,
            start_period: this.period.start,
            end_period: this.period.end,
            shops_ids : this.widgetOptions.table.segments_ids
          })
        }
          
      },
    },
    async mounted () {
      this.start_period = this.period.start
      this.end_period = this.period.end
      
      await this.resetWidgetOptions()
      
    },
    methods: {
      selectSegment(event){
        console.log("GET SEGMENTS FROM " + event.widgetName + ' ', event)

        if (event.widgetName === "clientProgram"){

          this.$store.dispatch('dashboard/clients/saveSegment', {segment_id : event.segment_id, program_id : this.program.id})
          this.widgetOptions.clients.all_clients = event.segment_id == 0 ? true : false
          this.widgetOptions.clients.segment_id = event.segment_id 


          this.$store.dispatch('dashboard/clients/widget', {
            program_id: this.program.id,
            start_period: this.start_period,
            end_period: this.end_period,
            all_clients : this.widgetOptions.clients.all_clients,
            segment_id : this.widgetOptions.clients.segment_id === 0 ? null : this.widgetOptions.clients.segment_id
          })
        }
        if (event.widgetName === "purchase"){

          this.$store.dispatch('dashboard/purchase/saveSegment', {segment_id : event.segment_id, program_id : this.program.id})
          this.widgetOptions.purchase.all_clients = event.segment_id == 0 ? true : false
          this.widgetOptions.purchase.segment_id = event.segment_id

          this.$store.dispatch('dashboard/purchase/widget', {
            program_id: this.program.id,
            start_period: this.start_period,
            end_period: this.end_period,
            all_clients : this.widgetOptions.purchase.all_clients,
            segment_id : this.widgetOptions.purchase.segment_id === 0 ? null : this.widgetOptions.purchase.segment_id
          })
        }
        if (event.widgetName === "movement"){

          this.$store.dispatch('dashboard/bonuses/saveSegment', {segment_id : event.segment_id, program_id : this.program.id})
          this.widgetOptions.movement.all_clients = event.segment_id == 0 ? true : false
          this.widgetOptions.movement.segment_id = event.segment_id

          this.$store.dispatch('dashboard/bonuses/widget', {
            program_id: this.program.id,
            start_period: this.start_period,
            end_period: this.end_period,
            all_clients : this.widgetOptions.movement.all_clients,
            segment_id : this.widgetOptions.movement.segment_id === 0 ? null : this.widgetOptions.movement.segment_id
          })
        }
        if (event.widgetName === "round"){
          console.log(event);
          this.$store.dispatch('dashboard/sharePurchase/saveSegment', {segment_id : event.segment_id, program_id : this.program.id})
          this.widgetOptions.round.segments_selected = event.segment_id.length > 0 ? true : false
          this.widgetOptions.round.segments_ids = this.$store.getters['dashboard/sharePurchase/segment'](this.program.id)
          console.log(event)
          
          if (this.widgetOptions.round.segments_ids.length && this.widgetOptions.round.segments_ids.length > 0)
            this.$store.dispatch('dashboard/sharePurchase/widget', {
              program_id: this.program.id,
              start_period: this.period.start,
              end_period: this.period.end,
              segments_selected :this.widgetOptions.round.segments_selected,
              segments_ids : this.widgetOptions.round.segments_ids.length === 0 ? [] : this.widgetOptions.round.segments_ids
            })
        }
        if (event.widgetName === "table"){

          if (event.segment_id.length > 0){

            this.widgetOptions.table.segments_ids = event.segment_id
            this.$store.dispatch('dashboard/table/saveSegment', {
              program_id: this.program.id, 
              segments_ids: this.widgetOptions.table.segments_ids, 
              tab : this.widgetOptions.table.tab
            })
            console.log(event)

            if (this.widgetOptions.table.tab === 0)
              this.$store.dispatch('dashboard/table/tabClient', {
                program_id: this.program.id,
                start_period: this.period.start,
                end_period: this.period.end,
                segments_ids : this.widgetOptions.table.segments_ids
              })
            if (this.widgetOptions.table.tab === 1)
              this.$store.dispatch('dashboard/table/tabNomenclatures', {
                program_id: this.program.id,
                start_period: this.period.start,
                end_period: this.period.end,
                segments_ids : this.widgetOptions.table.segments_ids
              })
            if (this.widgetOptions.table.tab === 2)
              this.$store.dispatch('dashboard/table/tabShops', {
                program_id: this.program.id,
                start_period: this.period.start,
                end_period: this.period.end,
                shops_ids : this.widgetOptions.table.segments_ids
              })
            }
        }
      },
      clearSegment(widgetName){
        console.log("SEGMENT CLEARED FOR " + widgetName);
        if (widgetName === 'client'){
          this.$store.dispatch('dashboard/clients/clearSegment', this.program.id)
          this.selectSegment({widgetName : 'clientProgram', segment_id : this.$store.getters['dashboard/clients/segment'](this.program.id)})
        }
        if (widgetName === 'purchase'){
          this.$store.dispatch('dashboard/purchase/clearSegment', this.program.id)
          this.selectSegment({widgetName : 'purchase', segment_id : this.$store.getters['dashboard/purchase/segment'](this.program.id)})
        }
        if (widgetName === 'bonuses'){
          this.$store.dispatch('dashboard/bonuses/clearSegment', this.program.id)
          this.selectSegment({widgetName : 'movement', segment_id : this.$store.getters['dashboard/bonuses/segment'](this.program.id)})
        }
        if (widgetName === 'round'){
          this.$store.dispatch('dashboard/sharePurchase/clearSegment', this.program.id)
          this.selectSegment({widgetName : 'round', segment_id : this.$store.getters['dashboard/sharePurchase/segment'](this.program.id)})
        }
        if (widgetName === 'table'){
          this.widgetOptions.table.segments_ids = []
          this.$store.dispatch('dashboard/table/saveSegment', {
            program_id: this.program.id, 
            segments_ids: this.widgetOptions.table.segments_ids, 
            tab: this.widgetOptions.table.tab
          })
          //this.selectSegment({widgetName : 'table', segment_id : this.$store.getters['dashboard/table/segment'](this.program.id, this.widgetOptions.table.tab)})
        }
      },
      getSegmentById(id){
        console.log("GET SEGMENT BY ID" , id)
        if (id === null)
          return {name : "Выберите сегмент", color : "#B5B5C4", id: null}
        if (id !== 0){
          let foundSegment = this.segments.find((el) => {return el.id === id})
          if (!foundSegment){
            return {name : "Выберите сегмент", color : "#B5B5C4", id: null}
          }
          return foundSegment 
        }
        else 
          return {name : 'Все клиенты', color : "#6591FA", id: 0}
      },
      async resetWidgetOptions(newProgram){
        const programId = newProgram ? newProgram.id : this.program.id

        this.$store.dispatch('dashboard/clients/setDefault')
        this.$store.dispatch('dashboard/purchase/setDefault')
        this.$store.dispatch('dashboard/bonuses/setDefault')
        this.$store.dispatch('dashboard/sharePurchase/setDefault')

        await this.$store.dispatch('crm/segment/segments', {program_id : programId})
        await this.$store.dispatch('company/nomen_segment/getList',  {programId : programId})
        await this.$store.dispatch('company/program/listShop', {id : programId})

        this.widgetOptions.clients.all_clients = true
        this.widgetOptions.clients.segment_id = null
        this.widgetOptions.purchase.all_clients = true
        this.widgetOptions.purchase.segment_id = null
        this.widgetOptions.movement.all_clients = true
        this.widgetOptions.movement.segment_id = null
        this.widgetOptions.round.segments_selected = false
        this.widgetOptions.round.segments_ids = []
        this.widgetOptions.table.segments_ids = []

        this.selectSegment({widgetName : 'clientProgram', segment_id : this.$store.getters['dashboard/clients/segment'](this.program.id)})
        this.selectSegment({widgetName : 'purchase', segment_id : this.$store.getters['dashboard/purchase/segment'](this.program.id)})
        this.selectSegment({widgetName : 'movement', segment_id : this.$store.getters['dashboard/bonuses/segment'](this.program.id)})
        this.selectSegment({widgetName : 'round', segment_id : this.$store.getters['dashboard/sharePurchase/segment'](this.program.id)})
        this.selectSegment({widgetName : 'table', segment_id : this.$store.getters['dashboard/table/segment'](this.program.id, this.widgetOptions.table.tab)})
        console.log('segments',this.segments)

      },
      changeTableTab(e){
        this.widgetOptions.table.tab = e
        this.widgetOptions.table.segments_ids = this.$store.getters['dashboard/table/segment'](this.program.id, this.widgetOptions.table.tab)
        if (this.widgetOptions.table.segments_ids.length > 0 && this.table.length === 0)
          this.selectSegment({widgetName : 'table', segment_id : this.$store.getters['dashboard/table/segment'](this.program.id, this.widgetOptions.table.tab)})
        if(!this.$store.getters['dashboard/table/period'](this.widgetOptions.table.tab)){
          this.selectSegment({widgetName : 'table', segment_id : this.$store.getters['dashboard/table/segment'](this.program.id, this.widgetOptions.table.tab)})
        }
      },
      getTableSegmentById(){
        const foundSegments = []
        if (this.widgetOptions.table.tab === 0){
          this.widgetOptions.table.segments_ids.forEach((id) => {
            let element = this.segments.find((segment) => {return segment.id === id})
            foundSegments.push(element)
          })
        }
        if (this.widgetOptions.table.tab === 1){
          this.widgetOptions.table.segments_ids.forEach((id) => {
            let element = this.nomenclature.find((segment) => {return segment.id === id})
            foundSegments.push(element)
          })
        }
        if (this.widgetOptions.table.tab === 2){
          this.widgetOptions.table.segments_ids.forEach((id) => {
            let element = this.shops.find((segment) => {return segment.id === id})
            foundSegments.push(element)
          })
        }
        return foundSegments
      }
    },
  }
</script>

<style lang="scss" scoped>
.widget-line {
  padding: 22px 0 0 0;
}
</style>
