<template>
  <double-diagram-frame
    class="w-purchase"
    :diagram-data="[diagramNewData, diagramTotalData, diagramAvgData]"
    :diagram-labels="[diagramNewLabels, diagramNewLabels, diagramAvgLabels]"
    :diagram-height="46"
    title="Продажи"
    :sub-titles="subTitles()"
    :counts="[
      Number( widgetData.byProgramSum).toLocaleString(undefined, {style: currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: currency.alpha3}),
      Number( widgetData.totalSum).toLocaleString(undefined, {style: currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: currency.alpha3}),
      Number(widgetData.averageCheck).toLocaleString(undefined, {style: currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: currency.alpha3}),
    ]"
    :percentage-differences="[byProgramPercentageDifference, totalPercentageDifference, avgPercentageDifference]"
    name="purchase"
    :currency="currency"
    textHelp="<p>Продажи по программе: сумма продаж по программе лояльности за выбранный период</p><p>Продажи всего: сумма продаж по всем торговым точкам и компаниям аккаунта за выбранный период</p>"
    :segment="segment"
    v-on="$listeners"
  />
</template>

<script>
  import DoubleDiagramFrame from '@/views/widgets/frames/DoubleDiagramFrame'
  import WidgetFunctions from '@/views/widgets/mixins/WidgetFunctions.js'
  import FormatNumber from '@/mixins/formatNumber'

  export default {
    name: 'Purchase',
    components: { DoubleDiagramFrame },
    mixins: [WidgetFunctions, FormatNumber],
    props: {
      widgetData: {
        type: Object,
        default: () => {
          return [7].fill({
            count: 0,
            date_start: '2020-09-08',
            date_end: '2020-09-08',
          })
        },
      },
      currency: {
        type: Object,
        default: () => {
          return {
            alpha3: 'RUB'
          }
        }
      },
      segment : Object,
    },
    data () {
      return {
        totalWD: [0, 0, 0, 0, 0, 0, 0],
        byProgramWD: [0, 0, 0, 0, 0, 0, 0],
        byProgramPercentageDifference: 0,
        totalPercentageDifference: 0,
        avgPercentageDifference: 0
      }
    },
    computed: {
      diagramNewLabels () {
        return this.prepareDiagramLabels(this.widgetData.chart[0], 'count')
      },
      diagramTotalLabels () {
        return this.prepareDiagramLabels(this.widgetData.chart[1], 'count')
      },
      diagramAvgLabels () {
        return this.prepareDiagramLabels(this.widgetData.chart[2], 'count')
      },
      diagramNewData () {
        console.log(this.widgetData);
        console.log("DIAGRAM NEW DATA",this.$_.map(this.widgetData.chart[0], 'count'));
        return this.$_.map(this.widgetData.chart[0], 'count')
      },
      diagramTotalData () {
        return this.$_.map(this.widgetData.chart[1], 'count')
      },
      diagramAvgData () {
        return this.$_.map(this.widgetData.chart[2], 'count')
      },
    },
    watch: {
      widgetData (v) {
        if (v && v.chart[0] && v.chart[1] && v.chart[2]) {
          const byProgramData = v.chart[0]
          const totalData = v.chart[1]
          // this.byProgramPercentageDifference = this.relativeChange(byProgramData[byProgramData.length - 1], byProgramData[byProgramData.length - 2])
          // this.totalPercentageDifference = this.relativeChange(totalData[totalData.length - 1], totalData[totalData.length - 2])
          this.recalcPercents()
        }
      },
    },
    mounted () {
      // const byProgramData = this.widgetData.chart[0]
      // const totalData = this.widgetData.chart[1]
      // this.byProgramPercentageDifference = this.relativeChange(byProgramData[byProgramData.length - 1], byProgramData[byProgramData.length - 2])
      // this.totalPercentageDifference = this.relativeChange(totalData[totalData.length - 1], totalData[totalData.length - 2])
      this.recalcPercents()
    },
    methods: {
      recalcPercents () {
        this.byProgramPercentageDifference = this.relativeChange(this.widgetData.byProgramSum, this.widgetData.byProgramSumPrev)
        this.totalPercentageDifference = this.relativeChange(this.widgetData.totalSum, this.widgetData.totalSumPrev)
        this.avgPercentageDifference = this.relativeChange(this.widgetData.averageCheck, this.widgetData.averageCheckPrev)
        console.log('this.byProgramPercentageDifference', this.widgetData.byProgramSumPrev, this.widgetData.byProgramSum, this.byProgramPercentageDifference)
      },
      subTitles () {
        const words = ['покупка', 'покупки', 'покупок']
        return [
          this.formatNumberString(this.widgetData.byProgramCount) + ' ' + this.getWord(this.widgetData.byProgramCount, words) + ' по программе',
          this.formatNumberString(this.widgetData.totalCount) + ' ' + this.getWord(this.widgetData.totalCount, words) + ' всего',
          "Средний чек"
        ]
      },
      getWord (number, words) {
        const cases = [2, 0, 1, 1, 1, 2]
        return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
      },
    },
  }
</script>

<style lang="scss">

@import "@/styles/vuetify-preset-plus/light_theme/widgets/_purchase.scss";

</style>
