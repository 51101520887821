<template>
  <table-frame
    title="Сегменты клиентов"
    :sub-titles="['Сегмент', 'Клиентов', 'Доход', 'Средний доход']"
    textHelp="Статистика сегментов программы лояльности по количеству клиентов, доходу и среднему доходу за выбранный период"
    :data="segments"
  />
</template>

<script>
  import TableFrame from '@/views/widgets/frames/TableFrame'

  export default {
    components: { TableFrame },
    props: {
      currency: {
        type: Object,
        default: () => {
          return {
            alpha3: 'RUB'
          }
        }
      }
    },
    data () {
      return {
        widgetData: [],
      }
    },
    computed: {
      program () {
        return this.$store.getters['company/program/program']
      },
      segments () {
        console.log('this.segments')
        console.log(this.$store.getters['crm/segment/segmentsForWidget'])
        let data = this.$store.getters['crm/segment/segmentsForWidget'];
        if (data.length > 0) data.forEach((el)=> {
          el[2] = (el[2]).toLocaleString(undefined, {style: this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.currency.alpha3})
          el[3] = el[3].toLocaleString(undefined, {style: this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: this.currency.alpha3})
        })
        return data
      },
    },
    watch: {
      program (v) {
        if (v) this.fetchData()
      },
    },
    mounted () {
      this.fetchData()
    },
    methods: {
      async fetchData () {
        await this.$store.dispatch('crm/segment/segments', { program_id: this.program.id })
        // console.log('segments', this.segments)
        // console.log('SEGMENTS_')
      },
    },
  }
</script>
