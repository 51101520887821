<template>
  <round-frame
    class="w-share-purchase"
    title="Сравнение продаж"
    :all-count="allCount"
    :current-count="currentCount"
    :percentage-difference="percentageDifference"
    :segments="segments"
    name="sharedPurchase"
    textHelp='Сравнение сегментов по сумме доходов клиентов.'
    :displaySegments="segment"
    v-on="$listeners"
  />
</template>

<script>
  import RoundFrame from '@/views/widgets/frames/RoundFrame'

  export default {
    components: { RoundFrame },
    props: {
      widgetData: {
        type: Object,
        default: () => {
          return {}
        },
      },
      segment: Array
    },
    data () {
      return {
        chartData: {},
      }
    },
    computed: {
      allCount () {
        if (this.chartData && this.chartData.total_quantity_sales) {
          return Number.parseInt(this.chartData.total_quantity_sales)
        }
        return 0
      },
      currentCount () {
        if (this.chartData && this.chartData.quantity_sales_of_program	) {
          return Number.parseInt(this.chartData.quantity_sales_of_program	)
        }
        return 0
      },
      segments(){
        let segmentData = []
        // Если сегменты не выбраны, отображаем долю покупок от общей
        if (this.chartData && !this.chartData.sales_by_segments){
            segmentData.push({
              name : null, 
              percent: 0,
              percent_label: 0
            })
        }
        if(this.chartData && this.chartData.sales_by_segments){
          let segmentsLen = this.chartData.sales_by_segments.length

          //Если один сегмент выбран, отображаем его долю покупок от общей
          if (segmentsLen === 1){
            segmentData.push({
              name : this.chartData.sales_by_segments[0].segment_name, 
              percent: 100,
              percent_label : 100
            })
          }

          //Если два или три сегмента выбрано, отображаем доли между ними
          if (segmentsLen > 1){
            const salesSum = this.chartData.sales_by_segments.reduce((currentSum, el) => {
              return currentSum += el.count_sales
            }, 0)

            const percents = [
              salesSum > 0 ? 100 : 0, 
              salesSum > 0 ? 100 - Math.round((this.chartData.sales_by_segments[0].count_sales / salesSum) * 100) : 0,
              salesSum > 0 ? 100 - Math.round((this.chartData.sales_by_segments[0].count_sales / salesSum) * 100) - Math.round((this.chartData.sales_by_segments[1].count_sales / salesSum) * 100) : 0
            ]

            this.chartData.sales_by_segments.forEach((el, idx) => {
              segmentData.push({
                name : el.segment_name, 
                percent: percents[idx],
                percent_label : salesSum > 0 ? Math.round((el.count_sales / salesSum) * 100) : 0,
              })
            });
          }
        }
        return segmentData
      },
      percentageDifference () {
        if (this.chartData && this.chartData.byProgramSumPrev && this.chartData && this.chartData.byProgramSum && this.chartData.byProgramSumPrev > 0) {
          return Math.round(((Number.parseInt(this.chartData.byProgramSum) - Number.parseInt(this.chartData.byProgramSumPrev)) / this.chartData.byProgramSumPrev) * 100)
        }
        return 0
      },
    },
    watch: {
      widgetData (v) {
        // console.log('watch data...')
        // console.log(v)
        // console.log('watch dadta...')

        this.chartData = v
      },
    },
    mounted () {
      // console.log('diget data...')
      // console.log(this.widgetData)
      // console.log('diget data...')

      this.chartData = this.widgetData
    },
  }
</script>
