<template>
  <widget-template :class-name="widgetClasses">
    <template v-slot:header-left>
      <p class="body-m-semibold">
        {{ title }}
      </p>
      <iconify-icon
        class="icon-question ml-1"
        icon="question-circle-outlined"
        height="16"
        style="cursor: pointer;"
        @click="dialogHelp = true"
      />
      <dialog-help
        :dialog.sync="dialogHelp"
        :header="title"
        :text="textHelp"
      />
    </template>

    <template v-slot:header-right>
      <v-menu 
          v-model="filterMenu"
          offset-y
          :close-on-content-click="false"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="secondary"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon 
              :small="$vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? false : true"
              color="#6693FF"
            >
              $iconify_ion-options-outline 
            </v-icon>
          </v-btn>
        </template>
        <filter-menu 
          title="Фильтр" 
          :name="name"
          @close="filterMenu = false"
          :segment="segment"
          v-on="$listeners"
        >

        </filter-menu>

        </v-menu>
    </template>

    <template v-slot:body>
      <div
        :class="generateClassesByPrefix(widgetClasses, '__box')"
        class="d-flex flex-column"
      >
        <div class="segment-wrapper">
          <span :class="generateClassesByPrefix(widgetClasses, '__box-segment')">
            <segment-chip
              :name="segment.name"
              :color="segment.color"
              :maxWidth="segment.name === 'Выберите сегмент' ? '136px' : '185px'"
            />
          </span>
        </div>
        <div
          v-for="i in counts.length"
          :key="i"
          :class="generateClassesByPrefix(widgetClasses, '__box-container')"
          class="d-flex"
        >
          
          <div :class="generateClassesByPrefix(widgetClasses, '__box-info')">
            <div :class="generateClassesByPrefix(widgetClasses, '__info-title')">
              <p class="body-s-semibold">
                {{ subTitles[i-1] }}
              </p>
            </div>
            <div
              :class="generateClassesByPrefix(widgetClasses, '__info-statistics')"
            >
              <p class="statistics__number title-m-bold">
                {{ counts[i-1] }}
              </p>
              <span
                :class="percentageDifferences[i-1] >= 0 ? 'wc-success' : 'wc-error'"
                class="statistics__percent body-m-semibold"
              >{{ getPercentToDisplay(percentageDifferences[i-1]) }}</span>
            </div>
          </div>
          <div :class="generateClassesByPrefix(widgetClasses, '__box-diagram')">
            <base-line-graph
              :height="diagramHeight"
              :labels="diagramLabels[i-1]"
              :data="diagramData[i-1]"
              :tooltips="diagramOptionsToltips"
              :point-radius="diagramOptions.pointRadius"
              :point-border-width="diagramOptions.pointBorderWidth"
            />
          </div>
        </div>
      </div>
    </template>
  </widget-template>
</template>

<script>
  import WidgetFunctions from '@/views/widgets/mixins/WidgetFunctions.js'
  import WidgetTemplate from '@/views/widgets/components/WidgetTemplate'
  import BaseLineGraph from '@/views/widgets/components/graphs/BaseLineGraph'
  import DialogHelp from '@/views/widgets/frames/DialogHelp'
  import FilterMenu from '@/views/widgets/frames/FilterMenu.vue'
  import SegmentChip from '@/components/segment/SegmentChip'
  export default {
    name: 'DoubleDiagramFrame',
    components: {
      BaseLineGraph,
      WidgetTemplate,
      DialogHelp,
      FilterMenu,
      SegmentChip
    },
    mixins: [WidgetFunctions],
    inheritAttrs: false,
    props: {
      name: {
        type: String,
        default () {
          return undefined
        },
      },
      diagramData: {
        type: Array,
        default () {
          return [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0]]
        },
      },
      diagramLabels: {
        type: Array,
        default () {
          return [[0, 0, 0, 0, 0], [0, 0, 0, 0, 0]]
        },
      },
      diagramHeight: {
        type: Number,
        default: 90,
      },
      title: {
        type: String,
        default: '',
      },
      subTitles: {
        type: Array,
        default () {
          return ['title', 'title']
        },
      },
      counts: {
        type: Array,
        default () {
          return [0, 0]
        },
      },
      percentageDifferences: {
        type: Array,
        default () {
          return [0, 0]
        },
      },
      titles: {
        type: Array,
        default () {
          return []
        },
      },
      textHelp: {
        type: String,
        default: null,
      },
      currency: {
        type: Object,
        default: () => {
          return {
            alpha3: 'RUB'
          }
        }
      },
      segment : Object
    },
    data () {
      var _this = this
      return {
        selectedSegment : {name : "Выберите сегмент", color : "#B5B5C4"},
        dialogHelp: false,
        filterMenu : false,
        diagramOptions: {
          pointRadius: 4,
          pointBorderWidth: 2.5,
        },
      }
    },
    computed: {
      widgetClasses () {
        return this.parentClass !== undefined ? this.parentClass + ' f-double' : 'f-double'
      },
      diagramOptionsToltips () {
        var _this = this
        return {
            display: true,
            callbacks: {
              title: function (tooltipItem, data) {
                if (_this.name === 'purchase') {
                  return Number(tooltipItem[0].value).toLocaleString(undefined, {style: _this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: _this.currency.alpha3})
                } else {
                  return tooltipItem[0].value + ' ' + _this.declOfNum(parseInt(tooltipItem[0].value), _this.titles)
                }
              },
              label: function (tooltipItem, data) {
                var startDate = tooltipItem.xLabel.start_period
                var endDate = tooltipItem.xLabel.end_period

                const formatStart = _this.$moment(startDate, 'YYYY-MM-DD').format('D.MM.YYYY')
                const formatEnd = _this.$moment(endDate, 'YYYY-MM-DD').format('D.MM.YYYY')

                return formatStart + '-' + formatEnd
              },
            },
          }
      }
    },
    mounted () {

    },
    methods: {
      changeSegment(segment){
        console.log(segment)
        if (segment.segment_id > 0){
          const found = this.$store.getters['crm/segment/segments'].find((el) => {return el.id === segment.segment_id})
          this.selectedSegment = {name : found.name, color: found.color}
        }
        if (segment.segment_id === 0){
          this.selectedSegment = {
            name : "Все клиенты",
            color : "#6591FA"
          }
        }
      },
      clearSegment(){
        this.selectedSegment = {name : "Выберите сегмент", color : "#B5B5C4"}
      }
    },
  }
</script>

<style lang="scss" scoped>

@import "@/styles/vuetify-preset-plus/light_theme/widgets/frames/_double-frame.scss";

.secondary.v-btn{
  padding: 7px 10px !important;
  height: 29px;
  width: 35px;
  min-width: unset;
}

.v-menu__content.theme--light.menuable__content__active{
  border-radius: 12px 12px 12px 12px !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border : none;
  max-width: 527px;
  min-width: 527px;
}

.segment-wrapper{
  display: flex;
}

.segment-chip{
    padding: 7px 10px;
    border-radius : 5px;
}

</style>
