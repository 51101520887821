<template>
    <base-side-panel v-model="internalActive" :width="500">
        <div class="side-panel-wrapper">
            <div class="side-panel-title title-l-bold" >
                Настройки отчета
            </div>
            <div class="side-panel-block ">
                <p class="block-title title-m-bold">Общие параметры</p>
                <div class="side-panel-block-body">
                    <p class="body-title body-l-semibold">Отображаемые данные</p>
                    <p class="body-description body-m-regular">Выберите до 8 значений данных, которые будут отображаться в таблице</p>
                    <v-select 
                        v-model="selectedItems"
                        :height="60"
                        :items="activeItems"
                        placeholder="Выберите значение"
                        label="Выберите значение"
                        :menu-props="{ offsetY: true }"
                        multiple
                        clearable
                        chips
                        outlined
                        counter
                        @input="limiter($event)"
                    >
                    </v-select>
                </div>
            </div>
            <v-btn 
                class="secondary"
                :disabled="selectedItems.length === 0" 
                @click="changeHeaders"
            >
                Сохранить
            </v-btn>
        </div>
    </base-side-panel>
</template>

<script>
export default {
    props:{
        active :{type: Boolean, default: false},
        selected: Array,
        tab : Number,
    },
    data(){
        return{
            internalActive: false,
            selectedItems: this.selected,
            currentTab : 0,
            items : [],
            clientSegmentItems : [
                {
                    divider: true,
                    header: "Клиентские",
                },
                {
                    text: "Все клиенты",
                    value: "clients",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Новые клиенты",
                    value: "new_clients",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Активные клиенты",
                    value: "active_clients",
                    disabled: false,
                    divider: false,
                },
                // {
                //     text: "Новые активные",
                //     value: "new_active",
                //     disabled: false,
                //     divider: false,
                // },
                {
                    divider: true,
                    header: "Бонусные",
                },
                {
                    text: "Начислено бонусов",
                    value: "bonus_credit",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Списано бонусов",
                    value: "bonus_debit",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Сгорело бонусов",
                    value: "bonus_expire",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Начислено бонусов от суммы покупок",
                    value: "bonus_credit_sum",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Списано бонусов от суммы покупок",
                    value: "bonus_debit_sum",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Сгорело бонусов от суммы покупок",
                    value: "bonus_expire_sum",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Процент списания бонусов от количества начисленных",
                    value: "bonus_percent",
                    disabled: false,
                    divider: false,
                },
                {
                    divider: true,
                    header: "Финансовые",
                },
                {
                    text: "Доход",
                    value: "income",
                    disabled: false,
                    divider: false,
                },
                /*{
                    text: "Средний доход",
                    value: "avg_income",
                    disabled: true,
                    divider: false,
                },*/
                {
                    text: "Средний чек",
                    value: "avg_check",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Количество покупок",
                    value: "sales",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Частота покупок",
                    value: "sales_freq",
                    disabled: false,
                    divider: false,
                }
            ],
            nomenclatureItems : [
                {
                    text: "Всего товаров",
                    value: "items",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Количество покупок",
                    value: "items_count",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Доход",
                    value: "sales_income",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Средний доход",
                    value: "avg_income",
                    disabled: false,
                    divider: false,
                },           
                {
                    text: "Среднее количество товара в одном чеке",
                    value: "avg_count_in_check",
                    disabled: false,
                    divider: false,
                },           
                {
                    text: "Средняя скидка",
                    value: "avg_discount",
                    disabled: false,
                    divider: false,
                },           
                {
                    text: "Частота покупок товаров",
                    value: "items_freq",
                    disabled: false,
                    divider: false,
                },           
                {
                    text: "Количество клиентов купивших товар",
                    value: "items_client_count",
                    disabled: false,
                    divider: false,
                },           
            ],
            shopsItems :[
                // {
                //     divider: true,
                //     header: "Клиентские",
                // },
                // {
                //     text: "Клиентов совершивших покупки на данной точке",
                //     value: "shop_client_count",
                //     disabled: false,
                //     divider: false,
                // }, 
                {
                    divider: true,
                    header: "Финансовые",
                },
                {
                    text: "Покупок на точке",
                    value: "shop_sales_count",
                    disabled: false,
                    divider: false,
                }, 
                {
                    text: "Доход точки",
                    value: "shop_income",
                    disabled: false,
                    divider: false,
                }, 
                {
                    text: "Средний доход",
                    value: "shop_avg_income",
                    disabled: false,
                    divider: false,
                }, 
                {
                    text: "Средний чек",
                    value: "shop_avg_check",
                    disabled: false,
                    divider: false,
                }, 
                {
                    divider: true,
                    header: "Бонусные",
                },
                {
                    text: "Начислено бонусов",
                    value: "shop_bonus_credit",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Списано бонусов",
                    value: "shop_bonus_debit",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Сгорело бонусов",
                    value: "shop_bonus_expire",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Начислено бонусов от суммы покупок",
                    value: "shop_bonus_credit_sum",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Списано бонусов от суммы покупок",
                    value: "shop_bonus_debit_sum",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Сгорело бонусов от суммы покупок",
                    value: "shop_bonus_expire_sum",
                    disabled: false,
                    divider: false,
                },
                {
                    text: "Процент списания бонусов от количества начисленных",
                    value: "shop_bonus_percent",
                    disabled: false,
                    divider: false,
                },
                {
                    divider: true,
                    header: "Другие",
                },
                {
                    text: "Действующие акции",
                    value: "shop_active_promo",
                    disabled: false,
                    divider: false,
                },
            ]
        }
    },
    created(){
    },
    computed:{
        activeItems(){
            if (this.tab === 0)
                return this.clientSegmentItems
            if (this.tab === 1)
                return this.nomenclatureItems
            if (this.tab === 2)
                return this.shopsItems
        }
    },
    watch:{
        active(v){
            this.internalActive = v;
            if (v){
                this.selectedItems = this.selected
            }
        },
        internalActive(v){
            this.$emit('activeChange', v)
        }
    },
    methods:{
        limiter(e){
            if (this.selectedItems.length >= 9)
                this.selectedItems.pop()
        },
        changeHeaders(){
            this.$emit('changeHeaders', this.selectedItems)
            this.internalActive = false
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/_typography.sass";

.side-panel-wrapper{
    padding: 50px 34px 34px 34px;
    
    .side-panel-title{
        margin-bottom: 3rem;
    }

    .side-panel-block{

        .block-title{
            margin-bottom: 2rem;
        }
        
        .body-description{
            margin-bottom: 1rem;
        }
    }

    .v-input__slot{
        height: unset !important;
        min-height: 60px !important;
       
    }

    .v-select__selections{
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }

    .v-input.theme--light.v-text-field.v-text-field--outlined .v-input__control .v-input__slot .v-input__append-inner{
        margin: auto;
    }
    
    
}

.theme--light.v-subheader{
    @include title-s-bold;
}



</style>