<template>
  <double-diagram-frame
    class="w-client-program"
    :diagram-data="[diagramTotalData, diagramNewData, diagramActiveData]"
    :diagram-labels="[diagramTotalLabels, diagramNewLabels , diagramActiveLabels]"
    :diagram-height="46"
    title="Клиенты программы"
    :titles="titles"
    :sub-titles="['Все клиенты', 'Новые клиенты', 'Активные клиенты']"
    :counts="[totalCount, newCount, activeCount]"
    :percentage-differences="[totalPercentageDifference, newPercentageDifference, activePercentageDifference]"
    name="clientProgram"
    text-help="<p>Всего: общее количество клиентов за выбранный период</p><p>Новые клиенты: количество уникальных клиентов за выбранный период</p><p>Активные клиенты - количество клиентов, совершивших покупки за выбранный период </p>"
    :segment="segment"
    v-on="$listeners"
  />
</template>

<script>
  import DoubleDiagramFrame from '@/views/widgets/frames/DoubleDiagramFrame'
  import WidgetFunctions from '@/views/widgets/mixins/WidgetFunctions.js'

  export default {
    components: { DoubleDiagramFrame },
    mixins: [WidgetFunctions],
    props: {
      widgetData: {
        type: Array,
        default () {
          return [4].fill({
            count: 0,
            date_start: '2020-09-08',
            date_end: '2020-09-08',
          })
        },
      },
      segment : Object
    },
    data () {
      return {
        newCount: 0,
        totalCount: 0,
        activeCount: 0,
        newPercentageDifference: 0,
        totalPercentageDifference: 0,
        activePercentageDifference : 0,
        titles: ['клиент', 'клиента', 'клиентов'],
      }
    },
    computed: {
      diagramNewLabels () {
        return this.prepareDiagramLabels(this.widgetData[0], 'count')
      },
      diagramTotalLabels () {
        return this.prepareDiagramLabels(this.widgetData[1], 'count')
      },
      diagramActiveLabels () {
        return this.prepareDiagramLabels(this.widgetData[2], 'count')
      },
      diagramNewData () {
        return this.$_.map(this.widgetData[0], 'count')
      },
      diagramTotalData () {
        return this.$_.map(this.widgetData[1], 'count')
      },
      diagramActiveData () {
        return this.$_.map(this.widgetData[2], 'count')
      },
    },
    watch: {
      widgetData (v) {
        if (v && v[0] && v[1] && v[2]) {
          const newData = v[0]
          const totalData = v[1]
          const activeData = v[2]
          this.newCount = newData.length ? newData[newData.length - 1].count : 0
          this.totalCount = totalData.length ? totalData[totalData.length - 1].count : 0
          this.activeCount = activeData.length ? activeData[activeData.length - 1].count : 0
          this.newPercentageDifference = this.relativeChange(newData[newData.length - 1].count ?? 0, newData[newData.length - 2].count ?? 0)
          this.totalPercentageDifference = this.relativeChange(totalData[totalData.length - 1].count ?? 0, totalData[totalData.length - 2].count ?? 0)
          this.activePercentageDifference = this.relativeChange(activeData[activeData.length - 1].count ?? 0, activeData[activeData.length - 2].count ?? 0)
        }
      },
    },
    mounted () {
      const newData = this.widgetData[0]
      const totalData = this.widgetData[1]
      const activeData = this.widgetData[2]

      if (newData.length > 0 && totalData.length > 0 && activeData.length > 0) {
        this.newCount = newData.length ? newData[newData.length - 1].count : 0
        this.totalCount = totalData.length ? totalData[totalData.length - 1].count : 0
        this.activeCount = activeData.length ? activeData[activeData.length - 1].count : 0
        
        this.newPercentageDifference = this.relativeChange(newData[newData.length - 1].count, newData[newData.length - 2].count)
        this.totalPercentageDifference = this.relativeChange(totalData[totalData.length - 1].count, totalData[totalData.length - 2].count)
        this.activePercentageDifference = this.relativeChange(activeData[activeData.length - 1].count, activeData[activeData.length - 2].count)

      }
    },
    methods:{
      
    }
  }
</script>

<style lang="scss">

@import "@/styles/vuetify-preset-plus/light_theme/widgets/_client-program.scss";

</style>
