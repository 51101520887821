<template>
  <base-empty-block-page

    title="Добавьте свою компанию в Plus!"
    action-icon="$iconify_ant-design-gift-outlined"
    action-text="Добавить компанию"
    action
    @action="$router.push('/master').catch(() => {});"
  >
    <template v-slot:image>
      <v-img
        src="@/assets/png/Empty-Company.png"
        max-width="215px"
        max-height="175px"
      />
    </template>
    <template v-slot:description>
      <span>Создавайте и настраивайте программы и сертификаты,<br> отслеживайте показатели продаж и многое другое.</span>
    </template>
  </base-empty-block-page>
</template>

<script>
  export default {

  }
</script>

<style lang="sass" scoped>

</style>
