<template>
  <widget-template :class-name="widgetClasses">
    <template v-slot:header-left>
      
    </template>

    <template v-slot:header-right>
      
    </template>

    <template v-slot:body>
      <div
        :class="generateClassesByPrefix(widgetClasses, '__box')"
        class="d-flex flex-column"
      >
        <div class="d-flex flex-row align-center">
          <p class="body-m-semibold">
            {{ title }}
          </p>
          <iconify-icon
            class="icon-question ml-1"
            icon="question-circle-outlined"
            height="16"
            style="cursor: pointer; display: inherit"
            @click="dialogHelp = true"
          />
          <dialog-help
            :dialog.sync="dialogHelp"
            :header="title"
            :text="textHelp"
          />
        </div>
        <div class="segment-wrapper">
            <segment-chip
              :name="segment.name"
              :color="segment.color"
              :maxWidth="segment.name === 'Выберите сегмент' ? '136px' : '185px'"
            />
        </div>
        <div
          v-for="i in 3"
          :key="i"
          :class="generateClassesByPrefix(widgetClasses, '__box-container')"
          class="d-flex"
        >
          <div :class="generateClassesByPrefix(widgetClasses, '__box-info')">
            <div :class="generateClassesByPrefix(widgetClasses, '__info-title')">
              <p class="body-m-semibold">
                {{ subTitles[i-1] }}
              </p>
            </div>
            <div
              :class="generateClassesByPrefix(widgetClasses, '__info-statistics')"
            >
              <div class="d-flex flex-column">
                <div class="d-flex flex-row">
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                      <span  class="body-xs-medium neutral-500--text">
                        <span v-if="i===1">Начислено</span> 
                        <span v-if="i===2">Списано</span> 
                        <span v-if="i===3">Сгорело</span> 
                        бонусов
                      </span>
                      <p class="statistics__number title-m-bold">
                          {{ Number(counts[i-1]).toLocaleString(undefined, {style: currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: currency.alpha3}) }}
                          <!-- {{ formatNumberString(counts[i-1]) }} ₽ -->
                      </p>
                    </div>
                    <div class="d-flex flex-column"> 
                      <span class="body-xs-medium text-right neutral-500--text">Доля от суммы продаж</span>
                      <p class="statistics__percent-all title-m-bold text-right ">
                        {{ prepareDecimalNumberToDisplay(allPercentageDifferences[i-1]) }}%
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-space-between ml-1">
                    <span primary class="body-xs-medium neutral-500--text">Изменение</span>
                    <div 
                      :class="percentageDifferences[i-1] >= 0 ? 'wc-success' : 'wc-error'"
                      class="statistics__percent body-m-semibold"
                    >
                      {{ percentageDifferences[i-1] == null ? '0%': percentageDifferences[i-1] > 0 ? `+${prepareDecimalNumberToDisplay(percentageDifferences[i-1])}%` : `${prepareDecimalNumberToDisplay(percentageDifferences[i-1])}%` }}
                    </div>
                  </div>                 
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column"
        :class="generateClassesByPrefix(widgetClasses, '__diagram')"
      >
        <div class="d-flex flex-row justify-space-between align-center mb-1">
          <div class="d-flex flex-row body-m-semibold">
            <div class="legend">
              <div class="circle"></div>
              Начислено
            </div>
            <div class="legend">
              <div class="circle"></div>
              Списано
            </div>
            <div class="legend">
              <div class="circle"></div>
              Сгорело
            </div>
          </div>
          <div class="d-flex flex-row align-center">
            <div>
              <v-menu 
                v-model="filterMenu"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="secondary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon 
                      :small="$vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? false : true"
                      color="#6693FF"
                    >
                      $iconify_ion-options-outline 
                    </v-icon>
                  </v-btn>
                </template>
                <filter-menu 
                  title="Фильтр" 
                  name="movement"
                  @close="filterMenu = false"
                  :segment="segment"
                  v-on="$listeners"
                >

                </filter-menu>

              </v-menu>
            </div>
            <div>
              <v-menu 
                v-model="diagramTypeMenu"
                :nudge-width="150"
                offset-y
                style="max-width: 150px"
                :close-on-content-click="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#6693FF"
                    v-bind="attrs"
                    v-on="on"
                    class="settings-icon"
                  >
                    <v-icon 
                      :small="$vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'xl' ? false : true"
                      color="#FFF"
                      size="16px"
                    >
                      $iconify_feather-settings
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in diagramTypes"
                    :key="index"
                    @click="selectedDiagramType = item.type"
                  >
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          
        </div>
        <triple-line-graph
          v-if="diagramData.length"
          :labels="diagramLabels"
          :data="diagramData"
          :height="diagramHeight"
          :type="selectedDiagramType"
          
        />
      </div>
    </template>
  </widget-template>
</template>

<script>
  import WidgetFunctions from '@/views/widgets/mixins/WidgetFunctions.js'
  import WidgetTemplate from '@/views/widgets/components/WidgetTemplate'
  import MultiFilledGraph from '@/views/widgets/components/graphs/MultiFilledGraph'
  import FormatNumber from '@/mixins/formatNumber'
  import DialogHelp from '@/views/widgets/frames/DialogHelp'
  import BarGraph from "@/views/widgets/components/graphs/BaseLineGraph";
  import TripleLineGraph from '@/views/widgets/components/graphs/TripleLineGraph.vue' 
  import FilterMenu from './FilterMenu.vue'
  import SegmentChip from '@/components/segment/SegmentChip'
  export default {
    name: 'TrippleFilledDiagramFrame',
    components: {
      MultiFilledGraph,
      WidgetTemplate,
      DialogHelp,
      BarGraph,
      TripleLineGraph,
      FilterMenu,
      SegmentChip
    },
    mixins: [WidgetFunctions, FormatNumber],
    inheritAttrs: false,
    watch: {
      diagramLabels(v) {
        let NewDateArray = []
        for (let index = 0; index < v.length; ++index) {
          if (v.length === index + 1) {
            NewDateArray.push(v[index] + "-" + this.$moment().format('DD.MM.YY'))
          } else {
            NewDateArray.push(v[index] + "-" + v[index + 1]);
          }
        }
        this.diagramLabelsFormat = NewDateArray
      }
    },
    props: {
      diagramData: {
        type: Array,
        default () {
          return [
            {
              stack: 1,
              label: 'Начислено',
              backgroundColor: '#4776E6',
              data: [0, 0, 0, 0, 0, 0, 0]
            },
            {
              stack: 1,
              label: 'Списано',
              backgroundColor: '#FFA338',
              data: [0, 0, 0, 0, 0, 0, 0]
            },
            {
              stack: 1,
              label: 'Сгорело',
              backgroundColor: '#95C5DA',
              data: [0, 0, 0, 0, 0, 0, 0]
            }
          ]
        },
      },
      diagramLabels: {
        type: Array,
        default () {
          return ['0.0', '0.0', '0.0', '0.0', '0.0', '0.0', '0.0']
        },
      },
      diagramHeight: {
        type: Number,
        default: 90,
      },
      title: {
        type: String,
        default: 'titile',
      },
      subTitles: {
        type: Array,
        default () {
          return ['title', 'title', 'title']
        },
      },
      counts: {
        type: Array,
        default () {
          return [0, 0, 0]
        },
      },
      allPercentageDifferences: {
        type: Array,
        default () {
          return [0, 0, 0]
        },
      },
      percentageDifferences: {
        type: Array,
        default () {
          return [0, 0, 0]
        },
      },
      textHelp: {
        type: String,
        default: null,
      },
      currency: {
        type: Object,
        default: () => {
          return {
            alpha3: 'RUB'
          }
        }
      },
      segment : Object
    },
    data () {
      return {
        dialogHelp: false,
        filterMenu : false,
        diagramTypeMenu : false,
        diagramTypes : [{type : 'line', label : 'График'}, { type  : 'bar', label: 'Столбчатая'}],
        selectedDiagramType : 'line',
        diagramLabelsFormat: [],
        selectedSegment : {name : "Выберите сегмент", color : "#B5B5C4"}
      }
    },
    computed: {
      widgetClasses () {
        return this.parentClass !== undefined ? this.parentClass + ' f-tripple-filled' : 'f-tripple-filled'
      },
      diagramOptionsToltips () {
        var _this = this
        return {
            display: true,
            callbacks: {
              // title: function (tooltipItem, data) {
              //   if (_this.name === 'purchase') {
              //     return Number(tooltipItem[0].value).toLocaleString(undefined, {style: _this.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: _this.currency.alpha3})
              //   } else {
              //     return tooltipItem[0].value + ' ' + _this.declOfNum(parseInt(tooltipItem[0].value), _this.titles)
              //   }
              // },
              label: function (tooltipItem, data) {
                var startDate = tooltipItem.xLabel.start_period
                var endDate = tooltipItem.xLabel.end_period
                console.log(tooltipItem)
                console.log(startDate, endDate);
                const formatStart = _this.$moment(startDate, 'YYYY-MM-DD').format('D.MM.YYYY')
                const formatEnd = _this.$moment(endDate, 'YYYY-MM-DD').format('D.MM.YYYY')

                return formatStart + '-' + formatEnd
              },
            },
          }
      }
    },
    mounted () {
      console.log("BREAKPOINT",this.$vuetify.breakpoint)
      console.log("TRIPLE DIAGRAM, DATA", this.diagramData)
    },
    methods: {
      changeSegment(segment){
        console.log(segment)
        if (segment.segment_id > 0){
          const found = this.$store.getters['crm/segment/segments'].find((el) => {return el.id === segment.segment_id})
          this.selectedSegment = {name : found.name, color: found.color}
        }
        if (segment.segment_id === 0){
          this.selectedSegment = {
            name : "Все клиенты",
            color : "#6591FA"
          }
        }
      },
      clearSegment(){
        this.selectedSegment = {name : "Выберите сегмент", color : "#B5B5C4"}
      }
    },
  }
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/widgets/frames/_tripple-filled-frame.scss";


</style>

<style lang="scss" scoped>
.v-menu__content.theme--light.menuable__content__active{
  border-radius: 12px 12px 12px 12px !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border : none;
  max-width: 527px;
}

.secondary.v-btn , .v-btn.theme--light.v-size--default{
  padding: 7px 10px !important;
  height: 29px;
  width: 35px;
  min-width: unset;
}

.settings-icon.v-btn.theme--light.v-size--default{
  margin-left: 10px;
  width: 35px;
  height: 29px;
}
.segment-wrapper{
  display: flex;
  margin-top: 10px;
}

.segment-chip{
    padding: 7px 10px;
    border-radius : 5px;
}
</style>