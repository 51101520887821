<template>
  <v-card class="card d-flex flex-column align-center" >
    <v-icon 
        class="filter-menu-close"
        size="30px"
        @click="closeMenu"
    >
        mdi-close
    </v-icon>
    <v-row>
        <v-col class="filter-menu-col">
            <p class="body-m-semibold filter-menu-title">{{ title }}</p>
            <p v-if="false" class="body-xs-medium filter-menu-description">{{ description }}</p>
            <div class="filter-menu-wrapper">
                <div class="filter-menu-header">
                    <div 
                        class="empty-chip"
                        v-if="selectedIds.length === 0"
                    >
                        <segment-chip
                            maxWidth="136px"
                            :name="emptyChip.name"
                            :color="emptyChip.color"
                        />
                    </div>
                    <div 
                        v-else
                        class="header-segments"
                    >
                        <div 
                            v-for="item, idx in items" 
                            :key="idx"
                        >                        
                            <segment-chip 
                                v-if="selectedIds.includes(item.id)"
                                max-width="185px"
                                :color="item.color"
                                :name="item.name"
                                close
                                @click:close="removeElement(item.id)"
                            />
                        </div>
                    </div>
                    
                    
                    <v-text-field
                        v-model="search"
                        placeholder="Найти"
                        hide-details
                        hide-spin-buttons
                        class="search-field"
                        append-icon="mdi-magnify"
                    >
                </v-text-field>
                </div>
                <div class="filter-menu-content">
                    <div class="content-segments">
                        <v-btn
                            class="segment-button"
                            v-for="item, idx in items" 
                            :key="idx"
                            @click="setSegmentId(item.id)"
                        >
                            <segment-chip 
                                max-width="317px"
                                :color="item.color"
                                :name="item.name"
                                
                            />
                        </v-btn>
                        
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
    <v-row>
        <v-col class="d-flex justify-center buttons-col">
            <v-btn 
                color="secondary" 
                @click="clearWidget"
            >
                Очистить
            </v-btn>
            <v-btn 
                color="#6693FF" 
                class="btn-submit"
                :disabled="!selectedIds.length"
                @click="updateWidget"
            >
                Применить
            </v-btn>
        </v-col>

    </v-row>
  </v-card>
</template>

<script>
import SegmentAutocomplete from '@/components/segment/SegmentAutocomplete.vue';
import SegmentChip from '@/components/segment/SegmentChip'
export default {
    components:{
        SegmentAutocomplete, SegmentChip,
    },
    props:{
        title: String,
        description : String,
        name: {type:String, default: "widget"},
        multiple: {type: Boolean, default: false},
        allUsers : {type :Boolean, default: true},
        maxLength: {type : Number, default : 1},
        type: {type : String, default : "segment"},
        segment: {type : [Object, Array]}
    },
    data(){
        return{
            selectedIds : [],
            emptyChip : {name : "Выберите сегмент", color : "#B5B5C4"},
            search : ""
        }
    },
    created(){
        //if (this.maxLength === 1)
        //    this.selectedIds.push(0)
    },
    mounted(){
        this.init()
    },
    computed:{
        items () {    
            if (this.type === "segment"){
                let data = []
                if (this.maxLength === 1)
                    data.push({
                        id : 0,
                        name : "Все клиенты",
                        color : "#6591FA"
                    })
                this.$store.getters['crm/segment/segments'].forEach((el) => {data.push(el)})
                if (this.search.length > 0)
                    data = data.filter((el) => {return el.name.toLowerCase().includes(this.search.toLowerCase())})
                return data;
            }
            if (this.type === "nomenclature"){
                let data = this.$store.getters['company/nomen_segment/list']
                if (this.search.length > 0){
                    data = data.filter((el) => {return el.name.toLowerCase().includes(this.search.toLowerCase())})
                }
                return  data

            }
            if (this.type === "shop"){
                let data = this.$store.getters['company/program/shops']
                if (this.search.length > 0){
                    data = data.filter((el) => {return el.name.toLowerCase().includes(this.search.toLowerCase())})
                }
                return  data
            }
                
            
        }
    },
    watch: {
        segment(v){
            this.init()
        }
    },
    methods:{
        setSegmentId(id){
            console.log(id)
            if (this.maxLength === 1){
                this.selectedIds.splice(0, 1)
                this.selectedIds.push(id)
            }
            else{
                let elem = this.selectedIds.find((el) => el === id)
                console.log(elem)
                if (!elem){
                    if (this.selectedIds.length < this.maxLength)
                        this.selectedIds.push(id)
                }
                else{
                    this.selectedIds.splice(this.selectedIds.indexOf(elem), 1)
                }
            } 
        },
        removeElement(id){
            const elem = this.selectedIds.indexOf(id)
            console.log(elem);
            if (elem != -1)
                this.selectedIds.splice(elem, 1)
        },
        clearWidget(){
            this.selectedIds = [];
            this.$emit('filterClear')
            this.$emit('close')
        },
        closeMenu(){
            this.$emit('close')
        },
        updateWidget(){
            if (this.maxLength === 1)
                this.$emit('selectSegment', {widgetName : this.name, segment_id : this.selectedIds[0]})
            else
                this.$emit('selectSegment', {widgetName : this.name, segment_id : this.selectedIds})
            this.$emit('close')
        },
        init(){
            this.selectedIds = []
            if (typeof this.segment === 'object' && !Array.isArray(this.segment)){
                if (this.segment.id !== null)
                    this.setSegmentId(this.segment.id)
            }
            console.log("IS ARRAY", Array.isArray(this.segment));
            if (Array.isArray(this.segment)){
                this.segment.forEach((el) => {
                    console.log("FILTER MENU INIT ARRAY ELEMENT", el)
                    if (el.id)
                        this.setSegmentId(el.id)
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">

$segments-border-color:#E6E6ED ;

.card{
    padding: 15px;
    width: 527px;
    min-height : 474px;
}

.filter-menu-close{
    position : absolute;
    top: 15px;
    right: 15px;
}

.filter-menu-col{
    padding : 0px;
}

.v-expansion-panel-content{
    max-width: 100%;
}

.row{
    width: 100%;
    margin: 0px;
}

.col{
    width: 100%;
}

.v-application--is-ltr .v-expansion-panel-header{
    border: 1px solid #B5B5C4;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    max-height: 48px;
    margin-bottom : 1rem;
}
.v-expansion-panel--active, .v-expansion-panel-header{
    min-height: 48px;
}

.v-expansion-panel-content{
    border: 1px solid #B5B5C4;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    padding-top: 16px;
}

.filter-menu-title{
    margin-bottom: 10px;
    line-height: 21px
}

.filter-menu-description{
    color : #70707D;
    font-size: 12px;
    margin-bottom: 9px;
    line-height: 21px
}


.filter-menu-content{    
    .content-segments{
        overflow-y: auto;

        height: 266px;
        padding-top : 10px;
        padding-left: 20px;
        padding-bottom : 15px;

        border: 0.5px $segments-border-color solid;
        border-top-color: #ffffffff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .v-btn.theme--light.v-size--default{
            padding : 0px 0px !important;
            height: unset;
            min-width: unset;
            border-radius : 5px;
        }
    }
    
    
    .segment-button{
        max-width: 317px;
        margin-bottom: 10px;
        display: block;
        background-color: rgba(255,255,255,255);
        text-align: left;
        overflow: hidden;
        text-overflow: clip;
    }
}

.filter-menu-header{
    display: flex;
    flex-direction: column;

    .empty-chip{
        margin-bottom: 10px;
    }

    .search-field{
        padding-left: 20px;
        padding-right: 17px;
        padding-top : 6px;
        padding-bottom: 6px;
        border: 0.5px $segments-border-color solid;
        border-top-left-radius : 10px;
        border-top-right-radius: 10px;
    }
    .header-segments{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .segment-chip{
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 5px 10px;
        }
    }
}

.segment-chip{
    padding: 7px 10px;
    border-radius : 5px;
}
.v-text-field{
    padding-top: 0px;
    margin-top: 0px;
}

.buttons-col{
    margin-top: 10px;
}
.btn-submit{
    margin-left: 10px;
    color: #FFF;
}

</style>