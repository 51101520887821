var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"elevation-0 widget-box"},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('v-tabs',{staticClass:"dashboard-table-tabs",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Сегменты клиентов ")]),_c('v-tab',[_vm._v(" Сегменты товаров ")]),_c('v-tab',[_vm._v(" Точки магазинов ")])],1),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"export-button",attrs:{"text":"","secondary":"","color":"#6693FF","loading":_vm.loading}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16px"}},[_vm._v(" $iconify_ion-document-outline ")]),_vm._v(" Экспорт ")],1)]}}]),model:{value:(_vm.exportMenu),callback:function ($$v) {_vm.exportMenu=$$v},expression:"exportMenu"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.exportXls('xlsx')}}},[_c('v-list-item-title',[_vm._v("Excel")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportXls('csv')}}},[_c('v-list-item-title',[_vm._v("CSV")])],1)],1)],1)],1),_c('div',{staticClass:"mr-1"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"secondary"},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.name === 'lg' || _vm.$vuetify.breakpoint.name === 'xl' ? false : true,"color":"#6693FF"}},[_vm._v(" $iconify_ion-options-outline ")])],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('filter-menu',_vm._g({attrs:{"title":"Фильтр","description":"Выберите сегмент клиентов, по которым будет отображаться статистика","name":"table","allUsers":false,"maxLength":4,"type":_vm.filterType,"segment":_vm.segments},on:{"close":function($event){_vm.filterMenu = false}}},_vm.$listeners))],1)],1),_c('v-btn',{staticClass:"settings-icon",attrs:{"color":"#6693FF"},on:{"click":function($event){_vm.sidePanel = true}}},[_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.name === 'lg' || _vm.$vuetify.breakpoint.name === 'xl' ? false : true,"color":"#FFF","size":"16px"}},[_vm._v(" $iconify_feather-settings ")])],1)],1)],1),_c('div',[_c('v-row',[(!_vm.loading)?_c('v-col',[_c('v-data-table',{ref:"dataTable",staticClass:"plus-table segments-table",attrs:{"options":_vm.tableSettings,"hide-default-footer":"","headers":_vm.tableHeaders,"items":_vm.tableData,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.segments",fn:function( ref ){
var item = ref.item;
return [_c('segment-chip',{style:('color: #000000; background-color: ' + _vm.hexToRgbA(item.bgColor, 0.15)),attrs:{"name":item.segmentName,"max-width":"100px","chip":""}})]}},{key:"item.clients",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientCount)+" ")]}},{key:"item.active_clients",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientActive)+" ")]}},{key:"item.income",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.salesSum)+" "+_vm._s(_vm.currency.symbol)+" ")]}},{key:"item.sales",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.salesCount)+" ")]}},{key:"item.avg_check",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.avgCheck.toFixed(2))+" "+_vm._s(_vm.currency.symbol)+" ")]}},{key:"item.sales_freq",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.salesFreq)+" ")]}},{key:"item.new_clients",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientNew)+" ")]}},{key:"item.bonus_credit",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bonusCredit)+" ")]}},{key:"item.bonus_debit",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bonusDebit)+" ")]}},{key:"item.bonus_expire",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bonusExpire)+" ")]}},{key:"item.bonus_credit_sum",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bonusCreditSum.toFixed(0))+" % ")]}},{key:"item.bonus_debit_sum",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bonusDebitSum.toFixed(0))+" % ")]}},{key:"item.bonus_expire_sum",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bonusExpireSum.toFixed(0))+" % ")]}},{key:"item.bonus_percent",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bonusPercent.toFixed(0))+" % ")]}},{key:"item.items",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.itemsAll)+" ")]}},{key:"item.items_count",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.itemsCount)+" ")]}},{key:"item.avg_count_in_check",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.avgCountInCheck.toFixed(2))+" ")]}},{key:"item.avg_discount",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.avgDiscount.toFixed(2))+" "+_vm._s(_vm.currency.symbol)+" ")]}},{key:"item.items_freq",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.itemsFreq.toFixed(2))+" ")]}},{key:"item.items_client_count",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.itemsClientCount)+" ")]}},{key:"item.avg_income",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.avgIncome.toFixed(2))+" "+_vm._s(_vm.currency.symbol)+" ")]}},{key:"item.sales_income",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.income)+" "+_vm._s(_vm.currency.symbol)+" ")]}},{key:"item.shop",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopName)+" ")]}},{key:"item.shop_sales_count",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopSalesCount)+" ")]}},{key:"item.shop_income",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopIncome)+" "+_vm._s(_vm.currency.symbol)+" ")]}},{key:"item.shop_avg_income",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopAvgIncome.toFixed(2))+" "+_vm._s(_vm.currency.symbol)+" ")]}},{key:"item.shop_avg_check",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopAvgCheck.toFixed(2))+" "+_vm._s(_vm.currency.symbol)+" ")]}},{key:"item.shop_bonus_credit",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopBonusCredit)+" ")]}},{key:"item.shop_bonus_debit",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopBonusDebit)+" ")]}},{key:"item.shop_bonus_expire",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopBonusExpire)+" ")]}},{key:"item.shop_bonus_credit_sum",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopBonusCreditSum.toFixed(0))+" % ")]}},{key:"item.shop_bonus_debit_sum",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopBonusDebitSum.toFixed(0))+" % ")]}},{key:"item.shop_bonus_expire_sum",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopBonusExpireSum.toFixed(0))+" % ")]}},{key:"item.shop_bonus_percent",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopBonusPercent.toFixed(0))+" % ")]}},{key:"item.shop_active_promo",fn:function( ref ){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopActivePromo)+" ")]}}],null,false,4213116663)})],1):_c('v-col',{staticClass:"loader-wrapper"},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"spinner__circle"},[_c('div',{staticClass:"spinner__circle-gradient"}),_c('div',{staticClass:"spinner__circle-inner"})])])])])],1)],1),_c('TableSidePanel',{attrs:{"selected":_vm.selectedHeaders,"active":_vm.sidePanel,"tab":_vm.tab},on:{"activeChange":function($event){_vm.sidePanel = $event},"changeHeaders":function($event){return _vm.setHeaders($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }